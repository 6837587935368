import React from "react";
import {
  heroBanner,
  large,
  heroBanner__caption,
  caption__content,
} from "../scss-modules/splash-banner.module.scss";

import HomeVideoMP4 from "../assets/video/performance-energy-welcome-video-1920-1080.mp4";
import HomeVideoWEBM from "../assets/video/performance-energy-welcome-video-1920-1080.webm";

export default function HomeSplashBanner({ title, content }) {
  return (
    <div className={`${heroBanner} ${large}`}>
      <div className="module media">
        <video muted autoPlay loop playsInline>
          <source src={HomeVideoMP4} type="video/mp4" />
          <source src={HomeVideoWEBM} type="video/webm" />
          Your browser does not support videos.
        </video>
      </div>

      <div className={heroBanner__caption}>
        <div className={caption__content}>
          <h1>{title}</h1>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
}
